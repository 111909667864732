/**
 *
 * */
function RnWebElementCreate(tagName, params, options, autoSetListeners = true) {
    const element = document.createElement(tagName);
    if (options === null || options === void 0 ? void 0 : options.innerHTML) {
        element.innerHTML = options.innerHTML;
    }
    if (options === null || options === void 0 ? void 0 : options.style) {
        element.setAttribute("style", options.style);
    }
    if (params) {
        if (autoSetListeners) {
            RnWebElementSetParamsAndListeners(element, params);
        }
        else {
            RnWebElementSetParams(element, params);
        }
    }
    return element;
}
/**
 *
 * */
function RnWebElementSetParams(element, params) {
    if (params) {
        Object.keys(params).forEach((key) => {
            element[key] = params[key];
        });
    }
}
/**
 * save listeners of element for later remove
 * */
class RnWebElementParamsAndListeners {
    /**
     *
     * */
    static add(el, event, listener) {
        if (!this.storage.has(el)) {
            this.storage.set(el, new Map());
        }
        const values = this.storage.get(el);
        if (!values.has(event)) {
            values.set(event, new Set());
        }
        const allListeners = values.get(event);
        if (!allListeners.has(listener)) {
            allListeners.add(listener);
        }
    }
    /**
     *
     * */
    static get(el, event) {
        if (!this.storage.has(el)) {
            this.storage.set(el, new Map());
        }
        const values = this.storage.get(el);
        if (!values.has(event)) {
            values.set(event, new Set());
        }
        return values.get(event);
    }
    /**
     * clear from all listeners saved in storage
     * */
    static clear(el) {
        if (this.storage.has(el)) {
            const values = this.storage.get(el);
            values.forEach((value, key) => {
                if (value && value.size) {
                    value.forEach((listener) => {
                        el.removeEventListener(key, listener);
                    });
                }
            });
            this.storage.delete(el);
        }
    }
    /**
     * add listeners and params
     * */
    static setParamsAndListeners(element, params, removeOld = false) {
        if (params) {
            Object.keys(params).forEach((key) => {
                if (typeof params[key] === 'function') {
                    if (removeOld) {
                        const values = this.get(element, key);
                        if (values && values.size) {
                            new Set(values).forEach((item) => {
                                element.removeEventListener(key, item);
                                values.delete(item);
                            });
                        }
                    }
                    element.addEventListener(key, params[key]);
                    this.add(element, key, params[key]);
                }
                else {
                    element[key] = params[key];
                }
            });
        }
    }
}
RnWebElementParamsAndListeners.storage = new Map();
/**
 *
 * */
function RnWebElementSetParamsAndListeners(element, params, reSetAllParams = false) {
    if (reSetAllParams) {
        const oldComponent = element;
        element = element.cloneNode(true);
        oldComponent.replaceWith(element);
    }
    if (params) {
        Object.keys(params).forEach((key) => {
            if (typeof params[key] === 'function') {
                element.addEventListener(key, params[key]);
            }
            else {
                element[key] = params[key];
            }
        });
    }
}

// import {RnStringToCapitalize} from "@rn-codebase/function/js/full/base/string.function";
function RnFuncDebounce(f, ms) {
    let isCooldown = false;
    return function () {
        if (isCooldown)
            return;
        f.apply(this, arguments);
        isCooldown = true;
        setTimeout(() => isCooldown = false, ms);
    };
}

/**
 *
 * */
class RnOutClick {
    constructor() {
        /**
         *
         * */
        this.containers = [];
        /**
         *
         * */
        this.invokeCallbackAlways = false;
    }
    /**
     * element has in containers
     * */
    static elementInContainers(containers, element) {
        return !!(containers && containers.find((container) => container && container.contains(element)));
    }
    /**
     *
     * */
    clearContainers() {
        /**/
        this.containers = [];
    }
    /**
     * init listener
     * */
    init(element, callback, data) {
        this.destroy();
        this.callback = callback;
        this.invokeCallbackAlways = (data === null || data === void 0 ? void 0 : data.invokeCallbackAlways) || false;
        this.containers = (data === null || data === void 0 ? void 0 : data.containers) || [];
        this.containers.push(element);
        const func = (event) => {
            const targetElement = event.target;
            const result = !RnOutClick.elementInContainers(this.containers, targetElement);
            if (this.invokeCallbackAlways || result) {
                if (typeof this.callback === 'function') {
                    this.callback({
                        event,
                        containers: this.containers,
                        outClick: result
                    });
                }
            }
        };
        const addEventListener = () => {
            document.addEventListener('click', this.clickHandler);
        };
        // @ts-ignore
        this.clickHandler = ((data === null || data === void 0 ? void 0 : data.debounceTime) || 0) > 0
            ? RnFuncDebounce(func, data === null || data === void 0 ? void 0 : data.debounceTime)
            : func;
        ((data === null || data === void 0 ? void 0 : data.firstDelayTime) || 0) > 0
            ? setTimeout(() => {
                addEventListener();
            }, data === null || data === void 0 ? void 0 : data.firstDelayTime)
            : addEventListener();
    }
    /**
     * destroy listener
     * */
    destroy() {
        this.clearContainers();
        this.clickHandler
            ? document.removeEventListener('click', this.clickHandler) : null;
    }
}

/**
 *
 * */
function RnWebElementPositionGetRelativeOffset(relativeElement) {
    const yx = RnWebElementPositionGetAbsolute(relativeElement), top = yx[0], // relativeElement.offsetTop,
    width = relativeElement.offsetWidth, height = relativeElement.offsetHeight, left = yx[1];
    return {
        top: top,
        bottom: top + height,
        right: left + width,
        left: left,
        width: width,
        height: height
    };
}
/**
 * get absolute Position
 * */
function RnWebElementPositionGetAbsolute(el) {
    let el2 = el, curtop = 0, curleft = 0;
    if (document.getElementById || document.all) {
        do {
            curleft += el.offsetLeft - el.scrollLeft;
            curtop += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
            el2 = el2.parentNode;
            while (el2 !== el) {
                curleft -= el2.scrollLeft;
                curtop -= el2.scrollTop;
                el2 = el2.parentNode;
            }
        } while (el.offsetParent);
    }
    else if (document['layers']) {
        curtop += el.y;
        curleft += el.x;
    }
    return [curtop, curleft];
}

export { RnWebElementParamsAndListeners as R, RnWebElementCreate as a, RnOutClick as b, RnWebElementPositionGetRelativeOffset as c };
